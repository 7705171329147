import { Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { ConstantsStateModel } from "./constants.model";
import { Constants } from './constants.action';
import { LocalStoreService } from "app/shared/services/local-store.service";
import { ApiService } from "app/shared/services/api.service";
import { tap } from "rxjs/operators";

@State<ConstantsStateModel>({
    name: "constants",
    defaults: {
        Constants: null,
    },
})
@Injectable({ providedIn: 'root' })
export class ConstantsState implements NgxsOnInit {
    constructor(
        private api: ApiService,
        private ls: LocalStoreService
    ) {}

    ngxsOnInit(ctx: StateContext<ConstantsStateModel>) {
        let constants = this.ls.getItem('CONSTANTS');
        ctx.patchState({
            Constants: constants
        });
    }

    @Selector()
    static constants(state: ConstantsStateModel) {
        return state.Constants;
    }

    @Action(Constants.FetchConstants)
    FetchConstants({ getState, patchState }: StateContext<ConstantsStateModel>) {
        return this.api.getSatisticsSite().pipe(
            tap((result) => {
                if (result.body?.constants) {
                    this.ls.setItem('CONSTANTS', result.body.constants);
                    patchState({
                        Constants: result.body.constants,
                    });
                }
            })
        );
    }

    @Action(Constants.SetConstants)
    SetConstants({ patchState }: StateContext<ConstantsStateModel>, action: Constants.SetConstants) {
        patchState({
            Constants: action.payload.Constants,
        });
    }
} 