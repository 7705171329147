export namespace Constants {
    export class FetchConstants {
        static readonly type = '[Constants] Fetch Constants';
    }

    export class SetConstants {
        static readonly type = '[Constants] Set Constants';
        constructor(public payload: { Constants: any }) { }
    }

    export class GetConstants {
        static readonly type = '[Constants] Get Constants';
        constructor(public payload: { Constants: any }) { }
    }
} 