import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd
} from '@angular/router';
import { Subscription } from "rxjs";
import { ThemeService } from '../../../services/theme.service';
import { LayoutService } from '../../../services/layout.service';
import { filter, map } from 'rxjs/operators';
import { ApiService } from 'app/shared/services/api.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { Page } from 'app/shared/models/common.model';
import * as enums from "app/shared/enums/api-codes";
import { SingleTonService } from 'app/shared/services/single-ton.service';
import { Store } from '@ngxs/store';
import { Site } from 'app/core'
import { Constants } from 'app/core/constants/constants.action';
import { ConstantsState } from 'app/core/constants/constants.state';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
})
export class AdminLayoutComponent implements OnInit {
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;
  public innerWidth: number = 0;
  public scrollConfig = {}
  public layoutConf: any = {};
  public adminContainerClasses: any = {};
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isScreen: boolean;
  showSite = false;
  site = '';
  Sites = [];
  services = []
  page: Page = {
    totalDocs: 0,
    limit: 250,
    totalPages: 0,
    page: 0,
  };
  safeUrl: any;
  safeHtml: any;
  safeStyle: any;
  constructor(
    private router: Router,
    public themeService: ThemeService,
    private layout: LayoutService,
    private singleton: SingleTonService,
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private ls: LocalStoreService,
    private store: Store,

  ) {


    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });
    this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeChange: NavigationEnd) => {
        this.layout.adjustLayout({ route: routeChange.url });
        this.scrollToTop();
      });
  }


  async ngOnInit() {
    await this.getStates()
    await this.getTenantData();
    await this.getPublicData().then(() => {
      this.layoutConfSub = this.layout.layoutConf$.subscribe(async (layoutConf) => {
        this.layoutConf = layoutConf;
        this.adminContainerClasses = this.updateAdminContainerClasses(this.layoutConf);
        this.cdr.markForCheck();
      });
    }
    );


    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });

  }
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.layout.resizeWindow();
    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });
  }

  ngOnChanges() {
  }

  lat = [
    25.222840, 20.022840, 29.122840
  ]
  lng = [
    55.222840, 46.022840, 59.122840
  ]
  getStates() {
    let states = this.ls.getItem('states');
    if (!states)
      this.api.getState().subscribe(res => {
        this.singleton.States = res.body.data?.states;
        this.ls.setItem('states', this.singleton.States)
      })
    else {
      this.singleton.States = states;
      this.ls.setItem('states', states)
    }

  }

  getSites() {
    this.Sites = [];
    this.store.dispatch(new Site.FetchSites({ areaType: '', page: this.page })).subscribe(res => {
      this.singleton.AllSites = res?.site?.Sites
      this.Sites = res?.site?.Sites;
      this.ls.setItem("SITES", this.Sites);
      if (!this.ls.getItem('SITE')) {
        this.ls.setItem("SITE", this.Sites[0]);
        LayoutService.site = this.Sites[0]
        this.layout.publishCurrentSiteData({ site: this.Sites[0] });
        this.store.dispatch(new Site.SelectSite({ Site: this.Sites[0] }));
        this.ls.setItem("PUBLICData", LayoutService.site?.registeredServices);
        this.ls.setItem("CURRENCY", LayoutService.site.currency);
        this.ls.setItem("PUBLIC", LayoutService.site.registeredServices.public);
        this.ls.setItem("GYM", LayoutService.site.registeredServices.gym);
        this.ls.setItem("RESERVED", LayoutService.site.registeredServices.reserved);
        this.ls.setItem("VIP", LayoutService.site.registeredServices.vip);
        this.ls.setItem("COMMUNITY", LayoutService.site.registeredServices.community); 
        this.ls.setItem("OPENRESERVED", LayoutService.site.registeredServices.openReserved);
        let dataType = Object.keys(LayoutService.site.registeredServices);
        let Parkdata = [];
        let currentData = "";
        if (dataType && dataType?.length > 0) {
          dataType.forEach((ele) => {
            if (LayoutService.site.registeredServices[ele]) {
              Parkdata.push(ele);
            }
          });
          Parkdata.forEach((ele) => {
            currentData = ele;
          });
          this.api.publishTypeData({ type: currentData })
          this.ls.setItem('typeData', currentData)
          this.ls.setItem('TypeArray', Parkdata)
          ApiService.typeData = currentData;
          this.layout.publishCurrentType({ type: currentData });
        }
        this.layout.publishParkData(Parkdata);
      } else {
        LayoutService.site = this.ls.getItem('SITE');
        this.layout.publishCurrentSiteData({ site: LayoutService.site });
        this.store.dispatch(new Site.SelectSite({ Site: LayoutService.site }));
        this.layout.publishCurrentType({ type: this.ls.getItem('typeData') });
      }
      this.layout.publishSiteData({ site: this.Sites });
      if (this.Sites.length === 0)
        this.showSite = true;
    })

  }

  async getPublicData() {
    let services = this.ls.getItem('SERVICES');
    let sites = this.ls.getItem('SITES');
    let constants = this.ls.getItem('CONSTANTS');
    this.layout.publishCurrentType({ type: this.ls.getItem('typeData') });
    
    if (!services && !sites && !constants) {
        await this.api.getSatisticsSite().subscribe(async (res: any) => {
            if (res.status === enums.API_CODES.SUCCESS) {
                await this.ls.setItem('SERVICES', res.body.data);
                await this.ls.setItem('CONSTANTS', res.body.extras?.constants);
                this.store.dispatch(new Constants.SetConstants({ Constants: res.body.extras?.constants }));
                this.services = res.body.data;
            }
        }).add(this.getSites());
    } else {
        this.store.dispatch(new Constants.GetConstants({ Constants: constants }));
        this.store.dispatch(new Site.GetSites({ Sites: sites })).subscribe(res => {
            this.Sites = sites;
            this.singleton.AllSites = sites;
            this.layout.publishSiteData({ site: res?.site?.Sites });
        });
    }
  }
  async getTenantData() {
    let tenantData = this.ls.getItem('tenantData');
    if (!tenantData) {
      await this.api.getOrganizationService().
        subscribe(async res => {
          if (res.body.success) {
            const trueKeys = Object.keys(res.body.data).filter(key => res.body.data[key]);
            this.ls.setItem('tenantData', trueKeys);
            this.singleton.organizationServices = trueKeys;
            this.singleton.publishData({ tenantData: trueKeys })
          }
        })
    } else {
      this.singleton.organizationServices = tenantData;
      this.singleton.publishData({ tenantData: tenantData })

    }
  }
  getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  }
  scrollToTop() {
    if (document) {
      setTimeout(() => {
        let element;
        if (this.layoutConf.topbarFixed) {
          element = <HTMLElement>document.querySelector('#rightside-content-hold');
        } else {
          element = <HTMLElement>document.querySelector('#main-content-wrap');
        }
        if (element)
          element.scrollTop = 0;
        else
          window.scrollY = 0
      })
    }
  }
  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  sidebarMouseenter() {
    if (this.layoutConf.sidebarStyle === 'compact') {
      this.layout.publishLayoutChange({ sidebarStyle: 'full' }, { transitionClass: true });
    }
  }

  sidebarMouseleave() {
    if (
      this.layoutConf.sidebarStyle === 'full' &&
      this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange({ sidebarStyle: 'compact' }, { transitionClass: true });
    }
  }

  updateAdminContainerClasses(layoutConf) {
    return {
      'navigation-top': layoutConf.navigationPos === 'top',
      'sidebar-full': layoutConf.sidebarStyle === 'full',
      'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
      'compact-toggle-active': layoutConf.sidebarCompactToggle,
      'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
      'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
      'sidebar-closed': layoutConf.sidebarStyle === 'closed',
      'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side',
      'lap': this.isLaptop,
      'mob': this.isMobile,
      'scr': this.isScreen,
      'tab': this.isTablet
    }
  }


}