import { Inject, Injectable } from "@angular/core";
import { LayoutService } from "./layout.service";
import { AuthService, GenericError } from "@auth0/auth0-angular";

import { Observable, Subject, from, throwError } from "rxjs";
import { catchError, filter, mergeMap, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { HttpInterceptor } from "@angular/common/http";
// import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Page } from "../models/common.model";
import {
  AvailabilitySearchParam,
  SearchParam,
  SearchParamCount,
} from "../models/dataTable.model";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { AddPark, EditPark } from "../models/park.model";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class ApiService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        // this.snack.open(error.error, "Error", { duration: 4000 });
        if (error.status === 500) {
          this.router.navigate(["sessions/error"]);
        }
        if (error.status === 401) {
          this.logout();
          this.snack.open(error.error, "Error", { duration: 4000 });
          ////console.log('error is intercept')
          this.ls.removeItem("JWT_TOKEN");
          this.ls.removeItem("REF_JWT_TOKEN");
          //console.error(error);
          //console.error(JSON.stringify(error));
          return throwError(error.error);
        }
        if (error.status === 400) {
          console.log(error);
          this.toaster.error(
            error?.error.error ? error?.error?.error : "Error",
            "failed!",
            { timeOut: 4000 }
          );
        }
        if (error.status == 404) {
          this.publishErrorData({ data: error.error, message: "Not Found" });
          this.router.navigate(["sessions/404"]);
          return throwError(error.error);
        } else {
          return throwError(error.error);
        }
      })
    );
  }

  isProd = false;
  offset = new Date().getTimezoneOffset() / 60;
  apiUrl: string = "";
  apiUrlImage: string = "";
  timeeOutDuration = `${50000}`;
  headers = new HttpHeaders();
  static Token: string = "";
  static org_id: string = "";
  static sub: string = "";
  static RefreshToken: string = "";
  static access_type: string = "Bearer";
  static user_type: number = -1;
  serviceId: number = 1;
  type = "";
  static typeData = "";
  private ls = window.localStorage;
  pathName = "";
  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private toaster: ToastrService,
    private auth: AuthService,
    private layouts: LayoutService,
    private router: Router
  ) {
    this.getObservabletype().subscribe((ele) => {
      this.type = ele.type;
    });
    // this.type = site === "City Centre Mirdif" ? "vip" : "public";
    this.isProd = environment.production;
    this.apiUrl = environment.GateWayBaseURL;
    this.apiUrlImage = environment.GateWayBaseURL;
    this.pathName = window.location.pathname;
    ApiService.typeData = this.ls
      .getItem("typeData")
      ?.replace('"', "")
      .replace('"', "");
    this.type = this.ls.getItem("typeData")?.replace('"', "").replace('"', "");
    this.layouts.getCurrentTypeObservable().subscribe((ele) => {
      ApiService.typeData = ele.type;
      if (ele?.type) this.type = ele?.type;
    });
    ApiService.org_id = this.ls
      .getItem("org_id")
      ?.replace('"', "")
      .replace('"', "");
    ApiService.Token = this.ls.getItem("TOKEN");
  }
  ngOnInit(): void {
    if (this.ls.getItem("typeData")?.replace('"', "").replace('"', "")) {
      ApiService.typeData = this.ls
        .getItem("typeData")
        ?.replace('"', "")
        .replace('"', "");
      this.type = this.ls
        .getItem("typeData")
        ?.replace('"', "")
        .replace('"', "");
    }
    // if (this.pathName !== "/sessions/signin") { this.getPublicData(); }
    this.auth.error$
      .pipe(
        filter(
          (e) => e instanceof GenericError && e.error === "login_required"
        ),
        mergeMap(() => this.auth.loginWithRedirect())
      )
      .subscribe();
  }
  getAccessToken(): Promise<string | null> {
    return this.auth.idTokenClaims$
      .pipe(tap((claims) => localStorage.setItem("ACCESS_Token", claims.__raw)))
      .toPromise()
      .then(() => localStorage.getItem("ACCESS_Token"));
  }
  //////// Events to broadcast ///////
  private UserUpdated = new Subject<any>();
  private ErrorUpdate = new Subject<any>();
  public publishSomeData(data: any) {
    this.UserUpdated.next(data);
  }
  public publishErrorData(data: any) {
    this.ErrorUpdate.next(data);
  }
  public getObservable(): Subject<any> {
    return this.UserUpdated;
  }
  public getObservableError(): Subject<any> {
    return this.ErrorUpdate;
  }
  private RoleUpdate = new Subject<any>();

  public publishRoleData(data: any) {
    this.RoleUpdate.next(data);
  }

  public getObservableRole(): Subject<any> {
    return this.RoleUpdate;
  }
  private typeSevice = new Subject<any>();

  public publishTypeData(data: any) {
    this.typeSevice.next(data);
  }

  public getObservabletype(): Subject<any> {
    return this.typeSevice;
  }

  //Logout
  logout() {
    this.ls.clear();
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
  convertToUTC(hour: number) {
    return (hour + this.offset + 24) % 24;
  }
  convertDateToUTC(date: string, time: number) {
    return new Date(new Date(date).setHours(Number(time))).toISOString();
  }
  //Method request
  getDataParking(API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    //////console.log(APIsService.Token)
    this.headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", ApiService.access_type + " " + ApiService.Token)
      .set(
        "X-TENANT-ID",
        ApiService.org_id
          ? ApiService.org_id
          : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
      )
      .set("Accept", "*/*");
    return this.http
      .get(API_URL, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }
  //post
  postDataParking(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token?.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
      // .set('hashed', hashed)
      // .set('aes', aes)
      //.set('timeout', this.timeeOutDuration);
    }
    //////console.log(data)
    return this.http
      .post(API_URL, data, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          if (!err?.success && err.errorCode === "400")
            this.toaster.error(
              err.error ? err.error : "something wrong",
              "Error!!",
              { timeOut: 4000 }
            );
          return throwError(err);
        })
      );
  }
  //patch
  patchDataParking(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    //////console.log(data)
    return this.http
      .patch(API_URL, data, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteDataParking(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    return this.http
      .delete(API_URL, {
        headers: this.headers,
        observe: "response",
        body: data,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  putDataParking(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    return this.http
      .put(API_URL, data, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  postFromData(data: FormData, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "*/*");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "multipart/form-data")
        .set("Accept", "application/json");
    }
    return this.http
      .post(API_URL, data, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  putFromData(data: FormData, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    return this.http
      .put(API_URL, data, { headers: this.headers, observe: "response" })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  getState() {
    let ApiUrl = `api/v1/state-code/list`;
    return this.getDataParking(ApiUrl);
  }
  //// not done
  getCountry() {
    let ApiUrl = `api/v1/dashboard/devices/sites?serviceType=${this.serviceId}`;
    return this.getDataParking(ApiUrl);
  }
  getUserProfile() {
    let ApiUrl = `profile/show`;
    return this.getDataParking(ApiUrl);
  }
  updateUserProfile(data: FormData) {
    let ApiUrl = `profile/update-info`;
    return this.putFromData(data, ApiUrl);
  }
  updateImageProfile(image: FormData) {
    let ApiUrl = `profile/update-image`;
    return this.putFromData(image, ApiUrl);
  }
  //// not done
  // ------End of Profile ------

  //get Device By ManufacturersId

  getCameraRequest(page: Page, search: SearchParam) {
    //console.log(search, LayoutService.site?.site)

    let ApiUrl = `api/v1/camera-requests/${this.type}/list?`;
    ApiUrl += `site=${search.site && search.site !== "undefined"
        ? search.site
        : LayoutService.site?.site
      }`;

    //console.log(this.type)
    if (this.type === "vip") {
      ApiUrl += `&whitelistFilterType=${search.type ? search.type : "ALL"}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (search.from != "" && search.from) {
      ApiUrl += `&from=${search.from}`;
    }
    if (search.to != "" && search.to) {
      ApiUrl += `&to=${search.to}`;
    }
    if (search.lane_name != "" && search.lane_name) {
      ApiUrl += `&lane=${search.lane_name}`;
    }
    if (search.plate_text != "" && search.plate_text) {
      ApiUrl += `&plate=${search.plate_text}`;
    }

    if (search.gate_type != "" && search.gate_type) {
      ApiUrl += `&gateType=${search.gate_type}`;
    }
    if (search.state != "" && search.state) {
      ApiUrl += `&state=${search.state}`;
    }
    if (search.gateRole != "" && search.gateRole) {
      ApiUrl += `&gateRole=${search.gateRole?.toUpperCase()}`;
    }
    return this.getDataParking(ApiUrl);
  }
  getBillReport(search: SearchParamCount) {
    let ApiUrl = `api/v1/reports/${this.type}/billing?`;
    ApiUrl += `&limit=1`;
    ApiUrl += `&skip=0`;

    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  //// not done
  getStatistices(search: SearchParam) {
    // let ApiUrl = `api/v1/get-data/dashboard?`;
    this.type = this.ls.getItem("typeData")?.replace('"', "").replace('"', "");
    //console.log(this.ls.getItem('typeData')?.replace('"', '').replace('"', ''))
    let ApiUrl = `api/v1/parking-stats/insights/${this.type}?`;
    // if (search.from != "" && search.from) {
    //   ApiUrl += `&from=${search.from}`;
    // }
    // if (search.to != "" && search.to) {
    //   ApiUrl += `&to=${search.to}`;
    // }
    ApiUrl += `&offset=${this.offset * -1}`;
    if (search.site != "" && search.site && search.site != "All") {
      ApiUrl += `&site=${search.site}`;
    }

    return this.getDataParking(ApiUrl);
  }
  getParkingstat(site: string) {
    let ApiUrl = `api/v1/parking-stats/availability?`;
    if (site != "" && site) {
      ApiUrl += `&site=${site}`;
    }
    return this.getDataParking(ApiUrl);
  }
  getSatisticsSite() {
    let ApiUrl = `api/v1/sites/services?`;
    return this.getDataParking(ApiUrl);
  }
  getOrganizationService() {
    let ApiUrl = `api/v1/organizations/services`;
    return this.getDataParking(ApiUrl);
  }

  activityDetails(plate: string, activityId: string) {
    let ApiUrl = `api/v1/public-activities?`;
    if (plate != "" && plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (activityId != "" && activityId) {
      ApiUrl += `&activityId=${activityId}`;
    }
    return this.getDataParking(ApiUrl);
  }
  duePayment(page: Page, site: string) {
    let ApiUrl = `api/v1/public-activities/due-payments?`;
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (site != "" && site) {
      ApiUrl += `&site=${site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }

  availability(search: AvailabilitySearchParam) {

    let apiUrl = `api/v1/parks/sections/list?`;
    apiUrl += `site=${search.site}`;
    apiUrl += `&areaType=${search.areaType}`;
    apiUrl += `&limit=${search.limit}`;
    apiUrl += `&skip=${search.skip}`;
    apiUrl += `&offset=${this.offset * -1}`;

    if (search.building) {
      apiUrl += `&building=${encodeURIComponent(search.building)}`;
    }
    if (search.level) {
      apiUrl += `&level=${encodeURIComponent(search.level)}`;
    }
    if (search.zone) {
      apiUrl += `&zone=${encodeURIComponent(search.zone)}`;
    }
    if (search.aisle) {
      apiUrl += `&aisle=${encodeURIComponent(search.aisle)}`;
    }


    return this.getDataParking(apiUrl);
  }

  getParkingStatsOccupation(search: AvailabilitySearchParam) {
    let apiUrl = `api/v1/parking-stats/parks/occupation?`;
    apiUrl += `site=${search.site}`;
    apiUrl += `&areaType=${search.areaType}`;
    apiUrl += `&limit=${search.limit}`;
    apiUrl += `&skip=${search.skip}`;
    apiUrl += `&offset=${this.offset * -1}`;

    if (search.building) {
      apiUrl += `&building=${encodeURIComponent(search.building)}`;
    }
    if (search.level) {
      apiUrl += `&level=${encodeURIComponent(search.level)}`;
    }
    if (search.zone) {
      apiUrl += `&zone=${encodeURIComponent(search.zone)}`;
    }

    return this.getDataParking(apiUrl);
  }

  getSections(site: string, areaType: string) {
    let apiUrl = `api/v1/parks/sections/all?`;
    apiUrl += `site=${site}`;
    apiUrl += `&areaType=${areaType}`;

    return this.getDataParking(apiUrl);
  }

  //// not done
  getEntrances(search: SearchParam) {
    let ApiUrl = `api/v1/get-data/entrances-exites?`;
    if (search.from != "" && search.from) {
      ApiUrl += `&from=${search.from}`;
    }
    if (search.to != "" && search.to) {
      ApiUrl += `&to=${search.to}`;
    }
    if (search.site != "" && search.site && search.site != "All") {
      ApiUrl += `&site=${search.site}`;
    }
    return this.getDataParking(ApiUrl);
  }

  getParkingFees(siteName: string) {
    let ApiUrl = `parking-fees`;
    let data = {
      siteCode: siteName,
    };
    let dataJson = JSON.stringify(data);
    return this.postDataParking(dataJson, ApiUrl);
  }

  setParkingFees(
    vat: number,
    reserved: number,
    public1: any,
    siteName: string,
    currency: string,
    overnight?: any
  ) {
    let ApiUrl = `parking-fees`;
    let data: any = {
      value: {
        vat: vat,
        currency: currency,
        reserved: reserved,
        public: public1,
        overnight: overnight,
      },
      siteCode: siteName,
      // expireAt:expireAt?new Date(expireAt).toISOString():expireAt,
    };
    let requestJSON = JSON.stringify(data);
    return this.putDataParking(requestJSON, ApiUrl);
  }
  getEntranceCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/entrances/count/per-lane?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getExitCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/exits/count/per-lane?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  entranceCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/entrances/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  exitsCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/exits/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getEntranceCountUnWhitelisted(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/entrances/count/per-lane/un-whitelisted?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getExitCountUnWhitelisted(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/exits/count/per-lane/un-whitelisted?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  entranceCountUnWhitelisted(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/entrances/count/un-whitelisted?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  exitsCountUnWhitelisted(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/exits/count/un-whitelisted?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservationsCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/reservations/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservationsExtendedCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/reservations/extended/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservationsCancelledCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/reservations/cancelled/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservationsActiveCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/reservations/active/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservationsInUseCount(search: any) {
    let ApiUrl = `api/v1/parking-stats/reservations/in-use/count?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  publicBillingSum(search: any) {
    let ApiUrl = `api/v1/parking-stats/public/bills/sum?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  publicFailedBillingSum(search: any) {
    let ApiUrl = `api/v1/parking-stats/public/bills/sum/failed?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  publicPendingBillingSum(search: any) {
    let ApiUrl = `api/v1/parking-stats/public/bills/sum/pending?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  publicValidateBillingSum(search: any) {
    let ApiUrl = `api/v1/parking-stats/public/bills/sum/validated?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  reservedBillingSum(search: any) {
    let ApiUrl = `api/v1/parking-stats/reserved/bills/sum?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  dwelTime(search: any) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/activities/dwel-time/avg?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  parkingTime(search: any) {
    let ApiUrl = `api/v1/parking-stats/public/activities/parking-time/avg?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getBill(search: any, page: Page) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/bills/list?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getFailedBill(search: any, page: Page) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/bills/list/failed?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getValidatedBill(search: any, page: Page) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/bills/list/validated?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  getPendingBill(search: any, page: Page) {
    let ApiUrl = `api/v1/parking-stats/${this.type}/bills/list/pending?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(
        search.fromDate,
        search.fromTime
      )}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(
        search.toDate,
        search.toTime
      )}`;
    }
    if (search.paymentMethod != "" && search.paymentMethod) {
      ApiUrl += `&paymentMethod=${search.paymentMethod}`;
    }
    if (search.fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(search.fromTime)}`;
    }
    if (search.toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(search.toTime)}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  trip(search: any, paginator: Page) {
    let ApiUrl = `api/v1/trips?`;
    if (search.plate_text) {
      ApiUrl += `&plate=${search.plate_text}`;
    }
    if (search.state) {
      ApiUrl += `&state=${search.state}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (search.from) {
      ApiUrl += `&fromDate=${search.from}`;
    }
    if (search.to) {
      ApiUrl += `&toDate=${search.to}`;
    }
    ApiUrl += `&areaType=${this.type.toUpperCase()}`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  unusedcar(search: any, paginator: Page) {
    let ApiUrl = `api/v1/whitelists/vip/list/un-used?`;
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (search.from) {
      ApiUrl += `&fromDate=${search.from}`;
    }
    if (search.to) {
      ApiUrl += `&toDate=${search.to}`;
    }
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }
  addGate(
    name: string,
    siteId: string,
    siteName: string,
    laneName: string,
    ipAddress: string,
    gateType: string,
    provider: string,
    areaType: string,
    IP: string,
    url: string,
    gateRole: string,
    linkedTo: string[],
    controlled: boolean,
    lat?: number | null,
    long?: number | null
  ) {
    let ApiUrl = `api/v1/sites/gates/add`;
    let data: any = {
      name: name,
      siteId: siteId,
      site: siteName,
      ipAddress: ipAddress,
      gateType: gateType,
      laneName: laneName,
      provider: provider,
      areaType: areaType,
      barrierIp: IP,
      gateRole: gateRole,
      controlled: controlled
    };
    if (linkedTo) data["linkedTo"] = linkedTo;
    if (url) data["url"] = url;
    if (lat) data["lat"] = lat;
    if (long) data["long"] = long;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  RegisterSite(
    name: string,
    parkingType: string,
    currency: string,
    long,
    lat,
    timezone,
    timezoneOffset,
    activeHour,
    start,
    end,
    hoursPerVisit,
    FreeVisitsCount,
    rectifyPublicAnomaly,
    actionBlacklist,
    gatesActivity,
    cashierShiftReport,
    blacklistedCarsReport
  ) {
    let ApiUrl = `api/v1/sites/register?`;
    let data: any = {
      name: name,
      parkingType: parkingType,
      currency: currency,
      lat: lat,
      long: long,
      timezone: timezone,
      timezoneOffset: timezoneOffset,
      workingHours: {
        active: activeHour,
        start: start,
        end: end,
      },
      gymAreaConfig: {
        hoursPerVisit: hoursPerVisit,
        FreeVisitsCount: FreeVisitsCount,
      },
      blacklistConfig: {
        action: actionBlacklist,
      },
      notifications: {
        gatesActivity: gatesActivity,
        cashierShiftReport: cashierShiftReport,
        blacklistedCarsReport: blacklistedCarsReport,
      },
      rectifyPublicAnomaly: rectifyPublicAnomaly,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  UpdateSite(
    name: string,
    siteId: string,
    parkingType: string,
    openReserved: boolean,
    LAT,
    LONG,
    activeHour,
    start,
    end,
    hoursPerVisit,
    FreeVisitsCount,
    rectifyPublicAnomaly,
    actionBlacklist,
    gatesActivity,
    cashierShiftReport,
    blacklistedCarsReport
  ) {
    let ApiUrl = `api/v1/sites/update?`;
    let data: any = {
      name: name,
      parkingType: parkingType,
      siteId: siteId,
      openReserved: openReserved,
      lat: LAT,
      long: LONG,
      rectifyPublicAnomaly: rectifyPublicAnomaly,

      workingHours: {
        active: activeHour,
        start: start,
        end: end,
      },
      gymAreaConfig: {
        hoursPerVisit: hoursPerVisit,
        FreeVisitsCount: FreeVisitsCount,
      },
      blacklistConfig: {
        action: actionBlacklist,
      },
      notifications: {
        gatesActivity: gatesActivity,
        cashierShiftReport: cashierShiftReport,
        blacklistedCarsReport: blacklistedCarsReport,
      },
    };
    console.log(data);

    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  getSiteById(
    siteId: string,
    gateType: string,
    type?: string,
    gateRole?: string
  ) {
    let ApiUrl = `api/v1/sites/gates/list?`;
    if (gateType) {
      ApiUrl += `&gateType=${gateType}`;
    }
    if (siteId) {
      ApiUrl += `&siteId=${siteId}`;
    }
    if (type) {
      ApiUrl += `&areaType=${type?.toUpperCase()}`;
    }
    if (gateRole) {
      ApiUrl += `&gateRole=${gateRole}`;
    }
    return this.getDataParking(ApiUrl);
  }
  getSites(parkingType: string, paginator: Page) {
    let ApiUrl = `api/v1/sites/list?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (parkingType) {
      ApiUrl += `&parkingType=${parkingType}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  updateGate(
    barrierIp: string,
    name: string,
    laneName: string,
    ipAddress: string,
    gateType: string,
    provider: string,
    areaType: string,
    gateId: string,
    gateRole: string,
    linkedTo: string,
    url: string,
    controlled: boolean,
    lat?: number | null,
    long?: number | null
  ) {
    let ApiUrl = `api/v1/sites/gates/update`;
    let data: any = {
      name: name,
      ipAddress: ipAddress,
      gateType: gateType,
      laneName: laneName,
      provider: provider,
      areaType: areaType,
      gateId: gateId,
      barrierIp: barrierIp,
      gateRole: gateRole,
      linkedTo: linkedTo,
      url: url ? url : null,
      lat: lat ? lat : null,
      long: long ? long : null,
      controlled: controlled
    };
    let requestJSON = JSON.stringify(data);
    return this.putDataParking(requestJSON, ApiUrl);
  }
  deleteGate(gateId: string, ipAddress: string) {
    let ApiUrl = `api/v1/sites/gates/delete`;
    let data: any = {
      gateId: gateId,
      ipAddress: ipAddress,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  reservationList(parkingType: string, page: Page) {
    let ApiUrl = `api/v1/sites/list?`;
    if (page.limit > 0) {
      ApiUrl += `limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (parkingType) {
      ApiUrl += `&parkingType=${parkingType}`;
    }
    return this.getDataParking(ApiUrl);
  }
  AddwhiteList(dataForm, type?) {
    console.log(type)
    let typeData = type?.toLowerCase() == 'public' ? 'permanent' : type?.toLowerCase()
    let ApiUrl = `api/v1/whitelists/${typeData}/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  AddWhiteListOrganization(dataForm) {
    let ApiUrl = `api/v1/organizations/whitelists/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  AddBlackListOrganization(dataForm) {
    let ApiUrl = `api/v1/organizations/blacklists/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  AddGYMwhiteList(dataForm) {
    let ApiUrl = `api/v1/whitelists/gym/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  removewhiteList(id: string, type?) {
    console.log(type)
    let areaType = (type && type?.toLowerCase() !== 'public') ? type?.toLowerCase() : 'permanent'
    let ApiUrl = `api/v1/whitelists/${areaType}/delete`;
    let data: any = {
      id: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  //whiteListVIP
  whiteList(site: string, page: Page, areaType, type, plate?: string, gate?: string, comment?: string) {
    let ApiUrl = `api/v1/whitelists/list?`;
    if (type) {
      ApiUrl += `&type=${type}`;
    }
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    if (plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (gate) {
      ApiUrl += `&gate=${gate}`;
    }
    if (comment) {
      ApiUrl += `&comment=${comment}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  whiteListOrganization(page: Page, areaType, type, plate?: string, gate?: string, comment?: string) {
    let ApiUrl = `api/v1/organizations/whitelists/list?`;
    if (type) {
      ApiUrl += `&type=${type}`;
    }
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (gate) {
      ApiUrl += `&gate=${gate}`;
    }
    if (comment) {
      ApiUrl += `&comment=${comment}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  blackListOrganization(page: Page, areaType, type, plate?: string, gate?: string, comment?: string) {
    let ApiUrl = `api/v1/organizations/blacklists/list?`;
    if (type) {
      ApiUrl += `&type=${type}`;
    }
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (gate) {
      ApiUrl += `&gate=${gate}`;
    }
    if (comment) {
      ApiUrl += `&comment=${comment}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  VIPAddwhiteList(dataForm) {
    let ApiUrl = `api/v1/whitelists/vip/create?`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  VIPRemovewhiteList(id: string) {
    let ApiUrl = `api/v1/whitelists/vip/delete?`;
    let data: any = {
      id: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  getReservedPark(site: string, page: Page) {
    let ApiUrl = `api/v1/reserved-parks/list?`;
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  //BlackList
  AddblackList(dataForm) {
    let ApiUrl = `api/v1/blacklists/${this.type}/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  //full site
  AddblackListFullSite(dataForm) {
    let ApiUrl = `api/v1/blacklists/site/create`;
    let data: any = dataForm;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  RemoveblackList(id: string, type?) {
    let areaType = (type) ? type : this.type
    let ApiUrl = `api/v1/blacklists/${areaType}/delete`;
    let data: any = {
      id: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  //whiteListVIP
  blackList(site: string, page: Page, plate?: string, gate?: string, comment?: string) {
    let ApiUrl = `api/v1/blacklists/list?`;
    ApiUrl += `&areaType=${this.type?.toUpperCase()}`;
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    if (gate) {
      ApiUrl += `&gate=${gate}`;
    }
    if (plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (comment) {
      ApiUrl += `&comment=${comment}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    return this.getDataParking(ApiUrl);
  }
  //Reserved Park
  addReservedPark(
    site: string,
    name: string,
    building: string,
    level: string,
    zone: string,
    spaces: number
  ) {
    let ApiUrl = `api/v1/reserved-parks/create?`;
    let data: any = {
      site: site,
      name: name,
      building: building,
      level: level,
      zone: zone,
      spaces: spaces,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  updateReservedPark(
    parkId: string,
    outOfService: number,
    disabled: boolean,
    name: string,
    building: string,
    level: string,
    zone: string,
    spaces: number
  ) {
    let ApiUrl = `api/v1/reserved-parks/update?`;
    let data: any = {
      parkId: parkId,
      name: name,
      building: building,
      level: level,
      zone: zone,
      spaces: spaces,
      outOfService: outOfService,
      disabled: disabled,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  removeReservedPark(id: string) {
    let ApiUrl = `api/v1/reserved-parks/delete?`;
    let data: any = {
      parkId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  //Parking Fees
  boundedDwelTime(search: any, page: Page) {
    let ApiUrl = `api/v1/parking-stats/public/activities/bounded-dwel-time?`;
    if (search.fromDate != "" && search.fromDate) {
      ApiUrl += `&fromDate=${search.fromDate}`;
    }
    if (search.toDate != "" && search.toDate) {
      ApiUrl += `&toDate=${search.toDate}`;
    }
    if (search.fromBound >= 0) {
      ApiUrl += `&fromBound=${search.fromBound}`;
    }
    if (search.toBound >= 0) {
      ApiUrl += `&toBound=${search.toBound}`;
    }
    if (search.site != "" && search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }

  //Users
  getUsers(id) {
    let ApiUrl = `organization/users`;
    let data = {
      orgId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  getUserById(id) {
    let ApiUrl = `organization/users/profile`;
    let data = {
      userId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  addUser(anyData: any, metaData?) {
    let ApiUrl = `organization/users/add`;
    let data = {
      email: anyData.email,
      password: anyData.password,
      orgId: ApiService.org_id,
      metadata: metaData,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  updateUser(userId, anyData: any) {
    let ApiUrl = `organization/users/update`;
    let data = {
      userId: userId,
      metadata: anyData,
    };
    let requestJSON = JSON.stringify(data);
    return this.patchDataParking(requestJSON, ApiUrl);
  }
  deleteUser(anyData: any) {
    let ApiUrl = `organization/users`;
    let data = {
      orgId: anyData.org_id,
      members: anyData.members,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteDataParking(requestJSON, ApiUrl);
  }
  deleteRole(anyData: any) {
    let ApiUrl = `role`;
    let data = {
      roleId: anyData.roleId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteDataParking(requestJSON, ApiUrl);
  }
  deleteRoleFromUser(anyData: any) {
    let ApiUrl = `organization/user-roles`;
    let data = {
      orgId: anyData.orgId,
      userId: anyData.userId,
      roles: anyData.roles,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteDataParking(requestJSON, ApiUrl);
  }
  getRoles(orgId, userId) {
    let ApiUrl = `organization/users/roles`;
    let data = {
      orgId: orgId,
      userId: userId,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  AssignRole(anyData: any) {
    let ApiUrl = `organization/user-roles`;
    let data = {
      orgId: anyData.orgId,
      userId: anyData.userId,
      roles: anyData.roles,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  addrole(anyData: any) {
    let ApiUrl = `role`;
    let data = {
      name: anyData.name,
      description: anyData.description,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  editrole(anyData: any) {
    let ApiUrl = `role/edit`;
    let data = {
      name: anyData.name,
      description: anyData.description,
      roleId: anyData.id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  getRolePermission(roleId) {
    let ApiUrl = `role/permissions`;
    let data = {
      roleId: roleId,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  getAllPermission() {
    let ApiUrl = `role/scope`;
    return this.getDataParking(ApiUrl);
  }
  getAllRoles() {
    let ApiUrl = `role`;
    return this.getDataParking(ApiUrl);
  }
  getAllRoles1() {
    let ApiUrl = `role/list`;
    let data = {
      orgId: ApiService.org_id,
    };
    let requestJSON = JSON.stringify(data);

    return this.postDataParking(requestJSON, ApiUrl);
  }
  AssignPermissionsToRole(anyData: any) {
    let ApiUrl = `role/associate-permissions`;
    let data = {
      roleId: anyData.roleId,
      permissions: anyData.permissions,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  removePermissionsFromRole(anyData: any) {
    let ApiUrl = `role/delete-permissions`;
    let data = {
      roleId: anyData.roleId,
      permissions: anyData.permissions,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteDataParking(requestJSON, ApiUrl);
  }
  exportGatePDF(
    reportUnitUris,
    types,
    jsonColumn,
    jsonRow,
    site,
    from,
    to,
    p_granularity,
    p_logo,
    chartValue?: any
  ) {
    let API_URL = `https://dev.tenant-management.parking.ldcs.ae/api/v1/organization/report`;
    let data = {
      reportUnitUri: reportUnitUris,
      async: false,
      outputFormat: "pdf",
      parameters: {
        reportParameter: [
          jsonRow,
          jsonColumn,
          chartValue,
          types,
          site,
          from,
          to,
          p_logo,
          p_granularity,
        ],
      },
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    let requestJSON = JSON.stringify(data);

    ////console.log(data)
    return this.http
      .post(API_URL, requestJSON, {
        headers: this.headers,
        responseType: "blob",
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  exportGateXLSX(
    reportUnitUris,
    types,
    jsonColumn,
    jsonRow,
    site,
    from,
    to,
    p_granularity,
    p_logo,
    chartValue?: any
  ) {
    let API_URL = `https://dev.tenant-management.parking.ldcs.ae/api/v1/organization/report`;
    let data = {
      reportUnitUri: reportUnitUris,
      async: false,
      outputFormat: "xlsx",
      parameters: {
        reportParameter: [
          jsonRow,
          jsonColumn,
          chartValue,
          types,
          site,
          from,
          to,
          p_logo,
          p_granularity,
        ],
      },
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    let requestJSON = JSON.stringify(data);

    ////console.log(data)
    return this.http
      .post(API_URL, requestJSON, {
        headers: this.headers,
        responseType: "blob",
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  exportPDF(Data, reportUnitUris) {
    let API_URL = `https://dev.tenant-management.parking.ldcs.ae/api/v1/organization/report`;
    let data = {
      reportUnitUri: reportUnitUris,
      async: false,
      outputFormat: "pdf",
      parameters: {
        reportParameter: Data,
      },
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    let requestJSON = JSON.stringify(data);
    ////console.log(data)
    return this.http
      .post(API_URL, requestJSON, {
        headers: this.headers,
        responseType: "blob",
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  exportExcel(Data, reportUnitUris) {
    let API_URL = `https://dev.tenant-management.parking.ldcs.ae/api/v1/organization/report`;
    let data = {
      reportUnitUri: reportUnitUris,
      async: false,
      outputFormat: "xlsx",
      parameters: {
        reportParameter: Data,
      },
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", ApiService.access_type + " " + ApiService.Token)
        .set(
          "X-TENANT-ID",
          ApiService.org_id
            ? ApiService.org_id
            : this.ls.getItem("org_id")?.replace('"', "").replace('"', "")
        )
        .set("Accept", "application/json");
    } else {
      this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
    let requestJSON = JSON.stringify(data);
    ////console.log(data)
    return this.http
      .post(API_URL, requestJSON, {
        headers: this.headers,
        responseType: "blob",
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getCarActivites(plate: string, state: string, site: string) {
    let ApiUrl = `api/v1/public-activities/info/active?plate=${plate}&state=${state}&site=${site}`;
    return this.getDataParking(ApiUrl);
  }
  validate(
    plate: string,
    state: string,
    site: string,
    arrival: string,
    userId: string,
    activityId: string,
    departureAttempt: string
  ) {
    let ApiUrl = `transaction/validate`;
    let data = {
      tenantId: ApiService.org_id,
      arrival: arrival,
      departureAttempt: departureAttempt,
      timeSpentInReservedArea: 0,
      plate: plate,
      state: state,
      site: site,
      userId: userId,
      activityId: activityId,
      type: 0,
      method: "VALIDATE",
    };
    let JsonData = JSON.stringify(data);
    return this.postDataParking(JsonData, ApiUrl);
  }
  logoSite(id: string, file) {
    let ApiUrl = `api/v1/sites/logo/upload`;
    file = file.replace(/^data:image\/[a-z]+;base64,/, "");
    let data = {
      siteId: id,
      logo: file,
    };
    let JsonData = JSON.stringify(data);

    return this.postDataParking(JsonData, ApiUrl);
  }
  gatesStats(areaType: string, site: string) {
    let ApiUrl = `api/v1/sites/gates/state?`;
    ApiUrl += `offset=${this.offset * -1}`;
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    } else {
      ApiUrl += `&areaType=${this.type.toUpperCase()}`;
    }
    if (site) {
      ApiUrl += `&site=${site}`;
    }

    return this.getDataParking(ApiUrl);
  }
  gatesStatsInActivity(
    areaType: string,
    site: string,
    fromDate: string,
    toDate: string,
    fromTime: number,
    toTime: number
  ) {
    let ApiUrl = `api/v1/sites/gates/inactivity?`;
    if (fromDate != "" && fromDate) {
      ApiUrl += `&fromDate=${this.convertDateToUTC(fromDate, fromTime)}`;
    }
    if (toDate != "" && toDate) {
      ApiUrl += `&toDate=${this.convertDateToUTC(toDate, toTime)}`;
    }
    if (fromTime >= 0) {
      ApiUrl += `&fromTime=${this.convertToUTC(fromTime)}`;
    }
    if (toTime >= 0) {
      ApiUrl += `&toTime=${this.convertToUTC(toTime)}`;
    }
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    ApiUrl += `&offset=${this.offset * -1}`;
    return this.getDataParking(ApiUrl);
  }

  //Open tickets
  getOpenTickets(searchData: SearchParam, page: Page) {
    let ApiUrl = `api/v1/public-activities/open-tickets/list?`;
    ApiUrl += `offset=${this.offset * -1}`;
    if (searchData.plate_text) {
      ApiUrl += `&plate=${searchData.plate_text}`;
    }
    if (searchData.state) {
      ApiUrl += `&state=${searchData.state}`;
    }
    if (searchData.site) {
      ApiUrl += `&site=${searchData.site}`;
    }
    if (searchData.from && searchData.from != "" && searchData.from) {
      ApiUrl += `&fromDate=${new Date(searchData.from).toISOString()}`;
    }
    if (searchData.to && searchData.to != "" && searchData.to) {
      ApiUrl += `&toDate=${new Date(searchData.to).toISOString()}`;
    }
    if (searchData.bill) {
      ApiUrl += `&withBill=${searchData.bill}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }
  //close Tickets
  closeTicket(id: string[], site: string, reason: string) {
    let ApiUrl = `api/v1/public-activities/open-tickets/close?`;
    let data = {
      ids: id,
      site: site,
      reason: reason,
    };
    let JsonData = JSON.stringify(data);

    return this.postDataParking(JsonData, ApiUrl);
  }
  //* Update comment Whitelist
  updateCommentWhiteList(anyData: any) {
    let ApiUrl = `api/v1/whitelists/edit`;
    let data = {
      name: anyData.name,
      comment: anyData.comment,
      id: anyData.id,
      expireAt: anyData.expireAt,
      gates: anyData.gates,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  //* Update comment Blacklist
  updateCommentBlackList(anyData: any) {
    let ApiUrl = `api/v1/blacklists/edit`;
    let data = {
      name: anyData.name,
      comment: anyData.comment,
      id: anyData.id,
      expireAt: anyData.expireAt,
      gates: anyData.gates,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  getParks(site: string, page: Page, areaType?: string) {
    let ApiUrl = `api/v1/parks/list?`;
    ApiUrl += `offset=${this.offset * -1}`;
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }
  getAvailability(site: string, areaType?: string) {
    let ApiUrl = `api/v1/parks/availability/area-type?`;
    ApiUrl += `offset=${this.offset * -1}`;
    if (areaType) {
      ApiUrl += `&areaType=${areaType}`;
    }
    if (site) {
      ApiUrl += `&site=${site}`;
    }
    return this.getDataParking(ApiUrl);
  }
  AddPark(park: AddPark) {
    let ApiUrl = `api/v1/parks/create`;
    let data = park;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  EditPark(park: EditPark) {
    let ApiUrl = `api/v1/parks/update`;
    let data = park;
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  deletePark(parkId: string) {
    let ApiUrl = `api/v1/parks/delete`;
    let data = {
      parkId: parkId,
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  getCommunitySectorsList(site: string, page: Page,name?:string) {
    let ApiUrl = `api/v1/community/sectors/list?`;

    if (site) {
      ApiUrl += `site=${site}`;
    }
    if (name) {
      ApiUrl += `&name=${name}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }

  getCommunityUnitsList(site: string, sector: string, page: Page,name?:string ) {
    let ApiUrl = `api/v1/community/units/list?`;

    if (site) {
      ApiUrl += `site=${site}`;
    }
    if (sector) {
      ApiUrl += `&sector=${sector}`;
    }
    if (name) {
      ApiUrl += `&name=${name}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }

  getCommunityUnit(id: string) {
    let ApiUrl = `api/v1/community/units/get?`;

    if (id) {
      ApiUrl += `id=${id}`;
    }

    return this.getDataParking(ApiUrl);
  }

  deleteCommunityUnit(id: string) {
    let ApiUrl = `api/v1/community/units/delete`;
    let data = {
      id: id
    };
    let requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  updateCommunityUnit(data) {
    const ApiUrl = `api/v1/community/units/update`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  createCommunityUnit(data: {
    category: string,
    site: string,
    sector: string,
    block: string,
    building: string,
    unit: string,
    doorNo: number,
    spaces: number
  }) {
    const ApiUrl = `api/v1/community/units/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  // Community Whitelist Owner Create
  createCommunityWhitelistOwner(data: any) {
    const ApiUrl = `api/v1/community/whitelists/owner/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  // Community Whitelist Owner Update 
  updateCommunityWhitelistOwner(data: any) {
    const ApiUrl = `api/v1/community/whitelists/owner/update`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  } 


  // Community Whitelist Tenant Create
  createCommunityWhitelistTenant(data: any) {
    const ApiUrl = `api/v1/community/whitelists/tenant/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }

  // Community Whitelist Tenant Update
  updateCommunityWhitelistTenant(data: any) {
    const ApiUrl = `api/v1/community/whitelists/tenant/update`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }



  // Community Whitelist Visitor Create
  createCommunityWhitelistVisitor(data: any) {
    const ApiUrl = `api/v1/community/whitelists/visitor/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  // Community Whitelist Visitor Update
  updateCommunityWhitelistVisitor(data: any) {
    const ApiUrl = `api/v1/community/whitelists/visitor/update`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  // Community Whitelist Delete
  deleteCommunityWhitelist(id: string) {
    const ApiUrl = `api/v1/community/whitelists/delete`;
    const data = { id };
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  // Community Whitelist Staff Create
  createCommunityWhitelistStaff(data: any) {
    const ApiUrl = `api/v1/community/whitelists/staff/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  // Community Whitelist Staff Update
  updateCommunityWhitelistStaff(data: any) {
    const ApiUrl = `api/v1/community/whitelists/staff/update`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
  

  // Community Whitelist  List
  getCommunityWhitelist(page: Page, site?: string, plate?: string, gate?: string, type?: any,comment?:string ) {
    let ApiUrl = `api/v1/community/whitelists/list?`;

    if (site) {
      ApiUrl += `&site=${site}`;
    }
    if (plate) {
      ApiUrl += `&plate=${plate}`;
    }
    if (gate) {
      ApiUrl += `&gate=${gate}`;
    }
    if (type) {
      ApiUrl += `&type=${type}`;
    }
    if (comment) {
      ApiUrl += `&comment=${comment}`;
    }
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }

  getCommunityWhitelists(id: string) {
    let ApiUrl = `api/v1/community/whitelists/get?id=${id}`;
    return this.getDataParking(ApiUrl);
  }

  getActionLogs(search: any, page: Page) {
    let ApiUrl = `api/v1/log/list?`;
    
    if (search.site) {
      ApiUrl += `&site=${search.site}`;
    }
    
    if (search.user) {
      ApiUrl += `&user=${search.user}`;
    }

    if (search.resource) {
      ApiUrl += `&resource=${search.resource}`;
    }

    if (search.resourceId) {
      ApiUrl += `&resourceId=${search.resourceId}`;
    }

    if (page.page >= 0) {
      ApiUrl += `&skip=${page.page * page.limit}`;
    }

    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }

    return this.getDataParking(ApiUrl);
  }

  // Add this method to the ApiService class
  createCommunitySector(data: {site: string, sector: string}) {
    const ApiUrl = `api/v1/community/sectors/create`;
    const requestJSON = JSON.stringify(data);
    return this.postDataParking(requestJSON, ApiUrl);
  }
}
